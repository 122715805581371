<template>
  <!-- <div class="about">
    <div class="player-container">
      <vue-core-video-player
        src="http://i3.vzan.cc/clip/926957901/1665428182/D5CF8D064B22EFB4.m3u8"
      ></vue-core-video-player>
    </div>
  </div> -->

  <div id="app">
    <template>
      <vue-aliplayer-v2
        :source="source"
        ref="VueAliplayerV2"
        :options="options"
        :skinLayout="skinLayout"
      />
    </template>
  </div>
</template>

<script>
import Vue from "vue";
// import VueCoreVideoPlayer from "vue-core-video-player";
// Vue.use(VueCoreVideoPlayer);
import VueAliplayerV2 from "vue-aliplayer-v2";

Vue.use(VueAliplayerV2);
// export default {
//   name: "about",
//   data() {
//     return {};
//   },
//   mounted() {},
//   methods: {},
// };
export default {
  data() {
    return {
      options: {
        // source:'//player.alicdn.com/video/aliyunmedia.mp4',
        // isLive: true, //切换为直播流的时候必填
        format: "m3u8", //切换为直播流的时候必填
        height: "220px",
        definition: "FD,LD",
        defaultDefinition:"FD,LD",
        skinLayout: [
          { name: "bigPlayButton", align: "cc" },
          {
            name: "H5Loading",
            align: "cc",
          },
          { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
          { name: "infoDisplay" },
          { name: "tooltip", align: "blabs", x: 0, y: 56 },
          { name: "thumbnail" },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              { name: "progress", align: "blabs", x: 0, y: 44 },
              { name: "playButton", align: "tl", x: 15, y: 12 },
              { name: "timeDisplay", align: "tl", x: 10, y: 7 },
              { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
              { name: "subtitle", align: "tr", x: 15, y: 12 },
              { name: "setting", align: "tr", x: 15, y: 12 },
              { name: "volume", align: "tr", x: 5, y: 10 },
            ],
          },
        ],
        
      },
      source:"http://i3.vzan.cc/clip/926957901/1665428182/D5CF8D064B22EFB4.m3u8"
      // source: '//ivi.bupt.edu.cn/hls/cctv1.m3u8',
    };
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
// .about {
//   width: 100%;
//   height: auto;
//   margin: 0 auto;
//   text-align: center;
// }
</style>
